// 
// About
// 

.about-section {
    padding-top: 10rem;
    padding-bottom: 10rem;
    background: linear-gradient(to bottom,
        $black 0%,
        #{fade-out($black, 0.1)} 75%,
        #{fade-out($black, 0.2)} 100%);

    p {
        color: $gray-500;
        margin-bottom: 2rem;
    }

    h2 {
        color: $white;
    }
}