// 
// Typography
//

@import "../../fonts";

// Override Bootstrap typography variables

$font-family-base: "Nunito Sans",
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji";

$base-letter-spacing: 0.0625em;

// Custom font family for masthead H1

$font-family-special: "Nunito Sans",
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji";