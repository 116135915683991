// 
// Global theme styling
// 

// Scroll padding for all scroll targets on page used with
// native CSS smooth scrolling
// 
// https://caniuse.com/?search=scroll-padding

html {
    scroll-padding-top: calc(4.5rem - 1px);
}

body {
    letter-spacing: $base-letter-spacing;
}