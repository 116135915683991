// 
// Navbar
// 

#mainNav {
    min-height: 3.5rem;
    background-color: $white;

    .navbar-toggler {
        font-size: 80%;
        padding: 0.75rem;
        color: $primary;
        border: $border-width solid $primary;

        &:focus {
            outline: none;
        }
    }

    .nav-link {
        color: $black;
        padding: 0.5rem;
        border-bottom: 0.25rem solid transparent;

        &:hover {
            color: $primary;
        }

        &:active {
            color: darken($primary, 15%);
        }

        &.active {
            background-color: $primary;
            outline: none;
        }
    }

    .navbar-brand {
        color: $black;
        font-weight: 700;
        padding: 0.9rem 0;
    }

    .navbar-nav {
        .nav-item {
            &:hover {
                color: $black;
                outline: none;
                background-color: transparent;
            }

            &:active,
            &:focus {
                outline: none;
                background-color: transparent;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: none;
        background-color: transparent;
        transition: background-color 0.3s ease-in-out;

        .navbar-brand {
            padding: 0.5rem 0;
            color: $black;
        }

        .nav-link {
            transition: none;
            padding: 1rem 0.5rem;
            color: $black;

            &:hover {
                color: fade-out($primary, 0.25);
            }

            &:active {
                color: $white;
            }

            &.active {
                color: $white;

                &:hover {
                    color: $black;
                }
            }
        }

        &.navbar-shrink {
            background-color: $white;

            .navbar-brand {
                color: $black;
            }

            .nav-link {
                color: $black;
                padding: 0.5rem;
                border-bottom: 0.25rem solid transparent;

                &:hover {
                    color: $primary;
                }

                &:active {
                    color: darken($primary, 15%);
                }

                &.active {
                    color: $white;
                    outline: none;
                    border-bottom: 0.25rem solid $primary;
                }
            }
        }
    }
}