@font-face {
  font-family: 'Nunito Sans';
  src: url(./assets/fonts/NunitoSans-bea68aa7001f8af1d93993bd413740cc.woff2?h=d15c2ee0af9279dec173b61338bf19fe) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url(./assets/fonts/NunitoSans-c4df9813978f21f03340e52ded9c98d5.woff2?h=d15c2ee0af9279dec173b61338bf19fe) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url(./assets/fonts/NunitoSans-1a21b38ef78345dc2898b83941135e04.woff2?h=d15c2ee0af9279dec173b61338bf19fe) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url(./assets/fonts/NunitoSans-016fe9e50bd3fe5996a4d88b825e6992.woff2?h=d15c2ee0af9279dec173b61338bf19fe) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url(./assets/fonts/NunitoSans-7b9083f01ff3bbd3c4877c436a080c2a.woff2?h=d15c2ee0af9279dec173b61338bf19fe) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}