// 
// Buttons
// 

.btn {
    box-shadow: 0 0.1875rem 0.1875rem 0 rgba(0, 0, 0, 0.1) !important;
    padding: 1.25rem 2rem;
    font-family: $font-family-special;
    font-size: 80%;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    border: 0;
}