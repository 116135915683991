@import "../Layout/greyscale/variables/colors";

div #masthead-photo {
  background: url("./bw-background-equity-scouts.jpg?h=440cf378625cec60a12f76aed2266970");
  background-size: cover;
  height: 1000px;
}

div #photo-padding {
  height: 300px;
}

div #title-box {
  background: rgba(33,37,41,0.84);
  padding: 2em;
}

